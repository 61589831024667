import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { history } from "@helloaudio/router";
import { getNextInvoice } from "./InvoiceSaga";
import { getAllUsers } from "./UserSaga";

export function* getSubscription({ invoice }) {
  yield put({ type: "SET_LOADING", key: "get-subscription", loading: true });
  const getSubscriptionCall = yield apiCall();
  const result = yield getSubscriptionCall.get(makeUrl("/subscription"), {});
  yield put({
    type: "SAVE_SUBSCRIPTION",
    subscription: result.data.subscription,
  });
  if (result.data.subscription.plan) {
    yield put({ type: "SAVE_LOCAL_USER", user: result.data.user });
  }
  profitwell("start", { user_email: result.data.user.last_known_email }); //eslint-disable-line
  if (invoice) {
    yield getNextInvoice();
  }
  yield put({ type: "SET_LOADING", key: "get-subscription", loading: false });
}

export function* updateSubscription({ plan, paymentMethodId, promoCode }) {
  yield put({ type: "SET_LOADING", key: "save-subscription", loading: true });
  const patchProfileCall = yield apiCall();
  if (paymentMethodId !== "") {
    yield patchProfileCall.post(makeUrl("/subscription"), {
      plan,
      paymentMethodId,
      promoCode,
    });
    yield put({
      type: "SET_LOADING",
      key: "save-subscription",
      loading: false,
    });
    yield put({ type: "SET_SAVED", key: "subscription" });
    yield put({
      type: "SET_SUCCESS",
      success: "Your subscription & billing preferences have been updated.",
    });
    yield getSubscription();
    yield history.push("/create-feed");
  }
}

export function* checkUpdateSubscription({ localUserId }) {
  const updateSubCall = yield apiCall();
  yield updateSubCall.post(makeUrl("/admin/check-subscription"), {
    localUserId,
  });
  yield getAllUsers();
}
