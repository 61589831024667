import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";
import { history } from "@helloaudio/router";

export function* getFeedEpisodes({ feedId, silent }) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed-episodes", loading: true });
  }
  const getFeedEpisodesCall = yield apiCall();
  const result = yield getFeedEpisodesCall.get(
    makeUrl("/feed-episodes?") + querystring.encode({ feedId }),
  );
  yield put({
    type: "SAVE_FEED_EPISODES",
    feedEpisodes: result.data.feedEpisodes,
  });
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-feed-episodes",
      loading: false,
    });
  }
}

export function* getFeedEpisode({ feedEpisodeId, silent }) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed-episode", loading: true });
  }
  const getFeedEpisodeCall = yield apiCall();
  const result = yield getFeedEpisodeCall.get(
    makeUrl("/feed-episode?") + querystring.encode({ feedEpisodeId }),
  );
  yield put({
    type: "SAVE_FEED_EPISODE",
    feedEpisode: result.data.feedEpisode,
  });
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed-episode", loading: false });
  }
}

export function* updateFeedEpisode({
  callback,
  description,
  dripAfter,
  episodeType,
  expirationDripAfter,
  explicit,
  feedEpisodeId,
  feedId,
  listenerTagsExpression,
  ordering,
  publishDate,
  refreshEpisodes,
  season,
  silent,
  title,
  universalFooter,
  useDolby,
}) {
  yield put({ type: "SET_LOADING", key: "save-feed-episode", loading: true });
  const postFeedEpisodeCall = yield apiCall();
  yield postFeedEpisodeCall.post(makeUrl("/feed-episode"), {
    feedEpisodeId,
    title,
    description,
    ordering,
    publishDate,
    dripAfter,
    expirationDripAfter,
    explicit,
    listenerTagsExpression,
    universalFooter,
    useDolby,
    season,
    episodeType,
  });
  yield put({ type: "SET_LOADING", key: "save-feed-episode", loading: false });
  yield put({ type: "SET_SAVED", key: "save-feed-episode" });
  if (!silent) {
    yield put({ type: "SET_SUCCESS", success: "Your episode has been saved" });
  }
  if (callback) {
    yield callback();
  }
  if (refreshEpisodes) {
    yield getFeedEpisodes({ feedId: refreshEpisodes, silent: true });
  } else {
    yield getFeedEpisode({ feedEpisodeId, silent });
    yield history.push(`/feed/${feedId}`);
  }
}

export function* updateEpisodeStatus({ feedId, feedEpisodeId, status, refresh, callback }) {
  let key;
  if (feedId) {
    key = status === "deleted" ? "delete-episode" : "save-episode-published";
  } else {
    key = `episode-status-${feedEpisodeId}`;
  }
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedEpisodeCall = yield apiCall();
  yield patchFeedEpisodeCall.patch(makeUrl("/feed-episode"), {
    feedEpisodeId,
    status,
  });
  yield put({ type: "SET_SAVED", key });
  if (status === "deleted") {
    yield put({
      type: "SET_SUCCESS",
      success: "Your episode has been deleted",
    });
  }
  yield getFeedEpisode({ feedEpisodeId });
  yield put({ type: "SET_LOADING", key, loading: false });
  if (callback) {
    yield callback();
  }
  if (feedId) {
    yield history.push(`/feed/${feedId}`);
  } else {
    if (status === "deleted") {
      yield getFeedEpisodes({ feedId: refresh });
    } else {
      yield put({ type: "SAVE_FEED_EPISODE_STATUS", feedEpisodeId, status });
    }
  }
}

export function* transcribeEpisode({ feedEpisodeId, callback }) {
  const key = "transcribe-episode";
  yield put({ type: "SET_LOADING", key, loading: true });
  const transcribeFeedEpisodeCall = yield apiCall();
  yield transcribeFeedEpisodeCall.post(makeUrl("/feed-episode/transcribe"), {
    feedEpisodeId,
  });
  yield callback();
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "SET_SAVED", key });
  yield put({
    type: "SET_SUCCESS",
    success: "Transcription in progress. Refresh page to download.",
  });
}

export function* finishSingleEpisodeUpload({ feedEpisodeId }) {
  yield getFeedEpisode({ feedEpisodeId, silent: true });
}

export function* massUpdateEpisodes({
  feedId,
  episodeIds,
  changeStatus,
  status,
  changeDescription,
  description,
  changeDripAfter,
  dripAfter,
  changeSeason,
  season,
  pendingEpisodes,
  callback,
}) {
  yield put({
    type: "SET_LOADING",
    key: "mass-update-episodes",
    loading: true,
  });
  const postMassUpdateCall = yield apiCall();
  yield postMassUpdateCall.post(makeUrl("/feed-episode/mass"), {
    episodeIds,
    changeStatus,
    status,
    changeDescription,
    description,
    changeDripAfter,
    dripAfter,
    changeSeason,
    season,
  });
  yield getFeedEpisodes({ feedId, silent: true });
  yield put({
    type: "SET_LOADING",
    key: "mass-update-episodes",
    loading: false,
  });
  if (pendingEpisodes) {
    yield put({
      type: "SET_SUCCESS",
      success:
        episodeIds.length === 1
          ? pendingEpisodes === 1
            ? `Successfully updated ${episodeIds.length} episode but ${pendingEpisodes} is still in progress.`
            : `Successfully updated ${episodeIds.length} episode but ${pendingEpisodes} are still in progress.`
          : `Successfully updated ${episodeIds.length} episodes but ${pendingEpisodes} are still in progress.`,
    });
  } else {
    yield put({
      type: "SET_SUCCESS",
      success:
        episodeIds.length === 1
          ? `Successfully updated ${episodeIds.length} episode.`
          : `Successfully updated ${episodeIds.length} episodes.`,
    });
  }
  if (callback) {
    yield callback();
  }
}

export function* massCopyEpisodes({ episodeIds, targetFeed, feedId, pendingEpisodes, callback }) {
  yield put({ type: "SET_LOADING", key: "mass-copy-episodes", loading: true });
  const postMassCopyCall = yield apiCall();
  yield postMassCopyCall.post(makeUrl("/feed-episode/copy"), {
    episodeIds,
    targetFeed,
  });
  yield put({ type: "SET_LOADING", key: "mass-copy-episodes", loading: false });

  if (pendingEpisodes) {
    yield put({
      type: "SET_SUCCESS",
      success: `Successfully copied ${episodeIds.length} episodes but ${pendingEpisodes} are still in progress.`,
    });
  } else {
    yield put({
      type: "SET_SUCCESS",
      success: `Successfully copied ${episodeIds.length} episodes.`,
    });
  }

  if (feedId) {
    yield getFeedEpisodes({ feedId });
  }

  if (callback) {
    yield callback();
  }
}
